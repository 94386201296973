<template>
  <v-card class="fill-height d-flex flex-column">
    <v-card-title class="py-3">
      {{ group.name }}
    </v-card-title>
    <v-card-subtitle class="pb-0">
      <v-col class="pb-0">
        <v-row dense>
          <span>
            <v-icon
              small
              class="pr-3"
            >
              mdi-text
            </v-icon>
            {{ group.description | ellipisize(100) }}
          </span>
        </v-row>
        <v-row
          v-if="group.permissions.can_be_a_step"
          dense
        >
          <v-icon
            small
            class="pr-3"
          >
            mdi-note
          </v-icon>
          <span> Valideur de FOP </span>
        </v-row>
        <v-row
          v-if="group.permissions.modify_fop_steps"
          dense
        >
          <v-icon
            small
            class="pr-3"
          >
            mdi-check-all
          </v-icon>
          <span> Gestion des étapes de FOP </span>
        </v-row>
      </v-col>
    </v-card-subtitle>
    <v-spacer />
    <v-card-actions>
      <v-row
        justify="center"
        dense
      >
        <v-col cols="auto">
          <TooltipBtn
            btn-color="orange"
            classes="white--text"
            icon="mdi-pencil"
            :click="() => $emit('edit')"
            tooltip="Modifier"
          />
        </v-col>
        <v-col cols="auto">
          <TooltipBtn
            btn-color="red"
            classes="white--text"
            icon="mdi-delete"
            :click="() => $emit('delete')"
            tooltip="Supprimer"
          />
        </v-col>
        <v-col cols="auto">
          <TooltipBtn
            btn-color="blue"
            classes="white--text"
            icon="mdi-account-multiple"
            :click="() => $emit('members')"
            tooltip="Membres"
          />
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import TooltipBtn from "../Inputs/TooltipBtn.vue";

export default {
  components: {TooltipBtn},
  props: {
    group: Object,
  }
};
</script>
