<template>
  <div>
    <v-card flat>
      <v-card-title
        class="mb-2 text-h4"
        style="word-break: keep-all"
      >
        Gestion des groupes
      </v-card-title>

      <v-card-text v-if="!loading">
        <v-row align="center">
          <v-col cols="auto">
            <v-btn
              elevation="1"
              outlined
              rounded
              @click="startGroupCreation()"
            >
              Créer un groupe
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="ml-auto"
          >
            <v-text-field
              v-model="search"
              outlined
              rounded
              hide-details="auto"
              dense
              label="Recherche"
              clearable
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="x in getGroups"
            :key="x._id"
            md="4"
            sm="6"
            cols="12"
          >
            <GroupCard
              :group="x"
              @edit="startGroupEdition(x)"
              @delete="deleteGroup(x)"
              @members="showGroupMembers(x)"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-progress-circular
        v-else
        class="d-block mx-auto"
        indeterminate
      />
    </v-card>

    <GroupModal
      v-if="!loading"
      ref="modal"
      v-model="modal.group"
      :goal="modal.goal"
      @done="loadGroups()"
    />

    <GroupMembers
      v-if="!loading"
      v-model="members.visible"
      :group_id="members.group"
    />
  </div>
</template>

<script>
import utils from "@/utils";

import GroupCard from "@/components/Groups/GroupCard";
import GroupModal from "@/components/Groups/GroupModal";
import GroupMembers from "@/components/Groups/GroupMembers";

export default {
  components: { GroupCard, GroupModal, GroupMembers },
  data: function () {
    return {
      groups: [],
      filtered_groups: [],
      createVisible: false,
      loading: true,
      search: "",
      modal: {
        goal: "",
        group: {
          name: "",
          description: "",
          fop_types: [],
          permissions: {
            can_be_a_step: false,
            modify_fop_steps: false,
          },
          can_bypass: [],
        },
      },
      members: {
        visible: false,
        group: undefined,
      },
    };
  },
  computed: {
    getGroups() {
      if (!this.search) {
        let results = this.groups;
        results.sort((a, b) => {
          if (a.permissions.can_be_a_step != b.permissions.can_be_a_step) {
            return a.permissions.can_be_a_step ? -1 : 1;
          }
          return a.name.localeCompare(b.name);
        });
        return results;
      }
      let reg = new RegExp(this.search, "i");
      let results = this.groups.filter(
        (x) => x.description.match(reg) || x.name.match(reg)
      );
      results.sort((a, b) => a.name.length - b.name.length);
      return results;
    },
  },
  created() {
    this.loadGroups();
  },
  methods: {
    loadGroups() {
      this.loading = true;
      this.axiosPost(process.env.VUE_APP_API_URL + "/admin/getAllGroups", {})
        .then((res) => {
          this.groups = res.data;
          this.loading = false;
        })
        .catch((err) => {
          utils.handle(this, err);
        });
    },
    showGroupMembers(group) {
      this.members = {
        visible: true,
        group: group._id,
      };
    },
    startGroupCreation() {
      this.modal.goal = "create";
      this.modal.group = {
        name: "",
        description: "",
        fop_types: [],
        permissions: {
          can_be_a_step: false,
          modify_fop_steps: false,
          see_public_all_realms: false,
        },
        can_bypass: [],
      };
      this.$refs.modal.open();
    },
    startGroupEdition(group) {
      this.modal = {
        goal: "update",
        group: group,
      };
      this.$refs.modal.open();
    },
    deleteGroup(group) {
      this.$alert.$emit("confirm", {
        title: "Supprimer " + group.name + " ?",
        message: "Supprimer le groupe ! Cette action est irréversible !",
        callback: () => {
          this.axiosPost(process.env.VUE_APP_API_URL + "/admin/deleteGroup", {
            group_id: group._id,
          })
            .catch((err) => {
              utils.handle(this, err);
            })
            .then(() => {
              this.loadGroups();
            });
        },
      });
    },
  },
};
</script>
