<template>
  <v-dialog
    v-model="dialog"
    max-width="1100"
  >
    <v-card class="pa-3">
      <v-card-title>
        <v-chip
          color="teal"
          class="mr-4"
          label
          text-color="white"
          small
        >
          {{ goal == "create" ? "Créer" : "Modfier" }}
        </v-chip>
        <span> Groupes d'utilisateur </span>
      </v-card-title>
      <v-card-text>
        <p v-if="errors.length">
          <b>Erreur{{ errors.length > 1 ? 's':'' }} :</b>
          <ul>
            <li
              v-for="(err,i) in errors"
              :key="i"
            >
              {{ err }}
            </li>
          </ul>
        </p>

        <v-stepper
          v-model="step"
          vertical
          non-linear
        >
          <v-stepper-step
            :complete="step > 1"
            step="1"
            editable
          >
            Informations
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-text-field
              v-model="value.name"
              label="Nom du groupe"
            />
            <v-text-field
              v-model="value.description"
              label="Description du groupe"
            />
          </v-stepper-content>

          <v-stepper-step
            :complete="step > 2"
            step="2"
            editable
          >
            Types de FOP
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-simple-table>
              <thead>
                <tr>
                  <th />
                  <th>Création</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(foptype, index) of fop_types"
                  :key="index"
                >
                  <td>{{ foptype.name }}</td>
                  <td><v-checkbox v-model="fop_types[index].permissions.create" /></td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-stepper-content>

          <v-stepper-step
            :complete="step > 3"
            step="3"
            editable
          >
            Permissions
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-checkbox
              v-model="value.permissions.modify_fop_steps"
              label="Modification d'étapes de FOP"
            />
            <v-checkbox
              v-model="value.permissions.can_be_a_step"
              label="Validation de FOP"
            />
            <v-checkbox
              v-model="value.permissions.see_public_all_realms"
              label="Visibilité globale de toutes les FOPs publiques (étudiant OU administration)"
            />
          </v-stepper-content>

          <v-stepper-step
            v-if="value.permissions.can_be_a_step"
            :complete="step > 4"
            step="4"
            editable
          >
            Courts-circuits
          </v-stepper-step>
          <v-stepper-content
            v-if="value.permissions.can_be_a_step"
            step="4"
          >
            <v-simple-table>
              <tbody>
                <tr
                  v-for="(usergroup, index) of stepsgroups"
                  :key="index"
                >
                  <td>{{ usergroup.name }}</td>
                  <td>
                    <v-checkbox v-model="bypasses[usergroup._id]" />
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-stepper-content>
        </v-stepper>
        <div class="d-flex">
          <v-btn
            color="primary"
            class="mx-auto"
            :loading="loading"
            :disabled="loading"
            @click="submit()"
          >
            Valider
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["value", "visible", "goal"],
  data: () => {
    return {
      step: 1,
      fop_types: [],
      stepsgroups: [],
      bypasses: {},
      dialog: false,
      loading: false,
      errors: [],
    };
  },
  watch: {
    value(val) {
      this.fop_types.forEach((foptype) => {
        let group_foptype = val.fop_types.find(
          (x) => x.foptype_id == foptype._id
        );
        foptype.permissions = group_foptype
          ? group_foptype.permissions
          : { create: false };
      });

      for (let sg of this.stepsgroups) {
        this.bypasses[sg._id] = val.can_bypass.includes(sg._id);
      }
    },
  },
  created() {
    this.axiosPost(process.env.VUE_APP_API_URL + "/groups/getAllStepsGroups")
      .then((res) => (this.stepsgroups = res.data))
      .catch((err) => utils.handle(this, err));

    this.axiosPost(process.env.VUE_APP_API_URL + "/admin/getFOPTypes", {}).then(
      (res) => {
        this.fop_types = res.data.map((foptype) => {
          let group_foptype = this.value.fop_types.find(
            (x) => x.foptype_id == foptype._id
          );
          foptype.permissions = group_foptype
            ? group_foptype.permissions
            : { create: false };
          return foptype;
        });
      }
    );
  },
  methods: {
    open() {
      this.dialog = true;
    },
    submit() {
      this.value.fop_types = [];
      this.fop_types.forEach((foptype, i) => {
        if (foptype.permissions.create) {
          this.value.fop_types.push({
            foptype_id: foptype._id,
            permissions: this.fop_types[i].permissions,
          });
        }
      });

      this.value.can_bypass = Object.entries(this.bypasses)
          .filter(([_, v]) => v)
          .map(([k, v]) => k);

      this.errors = [];
      if (!this.value.name || this.value.name == "")
        this.errors.push("Le groupe n'a pas de nom.");
      if (!this.value.description || this.value.description == "")
        this.errors.push("Le groupe n'a pas de description.");
      if (this.errors.length > 0) return;

      if (this.goal == "create") this.createGroup();
      if (this.goal == "update") this.updateGroup();
    },
    createGroup() {
      this.loading = true;
      this.axiosPost(process.env.VUE_APP_API_URL + "/admin/createGroup", {
        group: this.value,
      }).then(() => {
        this.dialog = false;
        this.loading = false;
        this.$emit("done");
      });
    },
    updateGroup() {
      this.loading = true;
      this.axiosPost(process.env.VUE_APP_API_URL + "/admin/updateGroup", {
        group: this.value,
      }).then(() => {
        this.dialog = false;
        this.loading = false;
        this.$emit("done");
      });
    },
  },
};
</script>